.sliderDiv{
  float: left;
}
.Headertitle{
    margin-top: 81px;
    font-family: Rubik-Light;
    font-size: 72px;
    float: left;
    margin-right: 19px;
    margin-bottom: 0;
    font-weight: 300;
    text-align: left;
    color: #ffffff;
  }
  .bold{
    margin-top: 6px;
    font-family: Rubik-Medium;
    font-weight: 900;
  }
  .Headertext{
    object-fit: contain;
    font-family: Rubik-Regular;
    font-size: 16px;
    margin-top: 55px;
    letter-spacing: 0.54px;
    text-align: left;
    color: #f5f5f5;
    float: left;
  }
  .headerDiv{
   
    float: left;
    width: 620px;
    margin-top: 153px;
    margin-left: -21px;
  }
  .headerButton{
    float: left;
    font-family: Rubik-Medium;
    font-size: 15px;
    margin-top: 11px;
    padding: 13px 33px;
    border-radius: 4px;
    outline: none;
    box-shadow: 0 30px 30px 0 rgba(0, 0, 0, 0.19);
    background-color: #3b435e;
    letter-spacing: 0.8px;
    border: none;
    text-align: left;
    color: #ffffff;
  }
  .tabletHeaderButton{
    font-size: 28px;
    margin-top: 61px;
    margin-left: 6px;
    padding: 22px 47px;
  }
  .headerButton:hover{
    background-color: #0081e9
  }
  .bigImg{
    float: left;
    height: 678px;
    margin-top: 134px;
    margin-left: 55px;
    margin-right: 82px;
  }
  .bigImg2{
    float: left;
    height: 586px;
    margin-top: 217px;
    margin-left: 35px;
    margin-right: 53px;
  }
  .bigImg3{
    float: left;
    height: 661px;
    margin-top: 142px;
    margin-left: 44px;
    margin-right: 65px;
}
  .divSlide{
      width: 100%;
      transition: transform 1000ms ease-in-out;
  }
  .slide{
    background-image:url('../../assets/images/backgroundHeader.svg'), linear-gradient(to bottom, #808490, #172038);
    width: 25%;
    height: 738px;
    float: left;
    margin-top: 2px;
    
  }
  .bold2{
    margin-top: 81px;
  }
  .bold3{
    margin-top: 108px;
  }
  .light{
    margin-top: 14px;
  }
.button2{
   background-color: #344985; 
}
.button3{
    background-color: #484680; 
 }
 .button4{
    background-color: #3b435e; 
 }
 .productsText{
  margin-top: 67px;
   float: left;
   font-family: Rubik-Regular;
  font-size: 40px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
  color: #0081e9;
  margin-right: 10px;
  margin-left: 7px;
 
}
 .partnersText{
  margin-bottom: 26px;
   float: left;
   font-family: Rubik-Regular;
  font-size: 40px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
  color: #0081e9;
  margin-right: 10px;
 }
 .partnersTextbold{
  margin-left: -10px;
  font-family: Rubik-Bold;
  font-weight: 800;
 }
 .partnersDiv{
   float: left;
    width: 100%;
 }
 
 .clientsLogo{
  width: 200px;
  height: 200px;
  top: 50%;
  transform: translateY(-50%);
 
  margin: 28% 91px; 
 }
 .logos{
  margin-top: 90px;
  float: left;
  width: 100%;
  position: absolute;
  padding-bottom: 85px;
}
 .titlePartners{
  position: absolute;
    right: 0;
    width: 20%;
 }
 .productsDiv{
  float: left;
  width: 100%;
  margin-top: 300px;
 }
 .productT{
  float: left;
  width: 722px;
  height: 712px;
  background-color: #f5f5f5;
}
.NewsT{
  float: left;
  width: 558px;
  height: 528px;
  background-color: #f5f5f5;
}
 .HImage{
  float: left;
  width: 801px;
  height: 712px;
}
 .logoProduct{
  height: 142px;
  width: 500px;
  margin-top: 123px;
  float: left;
  margin-left: 72px;
 }
.width1{
  width: 51%;
}
.width2{
  width: 20%;
  margin-left: 71px !important;
}
.name{
  margin-top: 205px;
  float: left;
    width: 524px;
    font-family: Rubik-Medium;
    font-size: 22px;
    margin-left: 21px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: 0.54px;
    text-align: left;
    color: #131d3b;
}
.aboutProduct{ 
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 33px;
  float: left;
  width: 415px;
  margin-left: 144px;
  margin-top: 40px;
  max-height: 202px;
  font-family: Rubik-Regular; 
  font-size: 15px;
  font-weight: 500;
  line-height: 1.67;
  letter-spacing: 0.54px;
  text-align: left;
  color: #131d3b;
  word-break: break-word;
}
.buttonProduct{
  background-color: #0081e9;
  box-shadow: 0 30px 30px 0 rgba(0, 0, 0, 0.12);
  margin-left: 144px;
  margin-top: 25px;
}
.productTitle{
  position: absolute;
  z-index: 1000;
} 
.NewsDiv{
  float: left;
    width: 100%;
}
.NewsTitle{
  width: 100%;
    height: 180px;
}
.newsText{
  margin-bottom: 0px;
    margin-top: 110px;
    margin-left: 83px !important;

}
.imageNews{
  height: 479px;
  width: 710px;
  float: left;
}

.newsT{
  background-color: #3b435e;
  height: 479px;
}
.titleNews{
  font-family: Rubik-Light;
  font-size: 31px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.18;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
  float: left;
  width: 392px;
  margin-top: 50px;
  margin-right: 230px;
  margin-left: 42px;
  }
  .dateNews{
    font-family: Rubik-Light;
    float: left;
    font-size: 12px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.9;
    padding-right: 11px;
    letter-spacing: 0.54px;
    text-align: left;
    color: #8c8b8b;
    width: 21%;
    margin-left: 0;
    margin-top: 0;
    margin-bottom: 0;
  }
.textNews{
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 43px;
  font-family: Rubik-Light;
  float: left;
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: 0.54px;
  text-align: left;
  color: #ffffff; 
  margin-right: 169px;
  width: 65%;
}
.buttonNews{
  background-color: #0081e9;
    box-shadow: 0 30px 30px 0 rgba(0, 0, 0, 0.12);
    margin-left: 43px !important;
    margin-top: 25px;
}
.FooterDiv{
  height: 100px;
  margin-top: 112px;
  float: left;
  right: 0; 
  left: 0;
  width: 100%;
}
.FooterDiv1{
  float: left;
  width: 47%;
  height: 100%;
  background-color: #e8eff3;
}
.FooterDiv2{
  height: 100%;
  float: left;
  width: 53%;
  background-color: #0060ad;
}
.logoFooter{
  width: 25%;
  margin: 3.1% 4%;
}
.linkText{
  font-family: Rubik-Regular;
  font-size: 17px;
  line-height: 1.2;
  text-align: left;
  color: #ffffff;
  text-decoration: none;
  outline: none;
  float: left;
  margin: 26px 27px;
}
.rights{
  font-family: Rubik-Light;
  font-size: 14px;
  margin: 29px 20px 0px 155px;
  float: left;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
}
.line{ 
  border: solid 0.1px #ffffff;
  height: 25px;
  margin-top: 22px;
  float: left;
}
.logosFooter{
  cursor: pointer;
  float: left;
  height: 36px;
  margin: 17px -1px 2px 32px;
}
.linkText:hover{
  color:#1a2441;
  font-weight: 800;
}
.span{
  position: absolute;
  background-color: black;
  width: 257px;
  height: 479px;
  opacity: 0.6;
  right: 0;
  margin-top: -483px;
  z-index: 1000;
}
.back1{
  background-image: url('../../assets/images/backgroundHeader.svg'),linear-gradient(to bottom, #374366, #182343);
}
.back2{
  background-image: url('../../assets/images/backgroundHeader.svg'),linear-gradient(to bottom, #647fcd, #17295a);
}
.back3{
  background-image: url('../../assets/images/backgroundHeader.svg'),linear-gradient(to bottom, #534e9b, #0e0e51);
}
.back4{
  background-image: url('../../assets/images/backgroundHeader.svg'),linear-gradient(to bottom, #808490, #172038);
}
.newsArrows{
  background-image: url('../../assets/images/whiteRight.svg'); 
    background-repeat: no-repeat;
    background-position: center;
    background-size: 43%;
    border-radius: 0px 3px 3px 0px;
    background-color: #0081e9;
    color: #ffffff;
    height: 46px; 
    width: 46px;
    border: none;
    outline: none;
    cursor: pointer;
}
 
.left{ 
  background-image: url('../../assets/images/whiteLeft.svg');
  opacity: 0.7;
  border-radius: 3px 0px 0px 3px;
}
.buttonProduct:hover,.buttonNews:hover,.newsArrows:hover{
  background-color: #ee8856
}

.arrowDivNews{
  position: absolute;
  margin-top: -50px;
  right: 10;
  right: 0;
  margin-right: 210px;
  z-index: 1001
}
.productsArrows{
  background-position: center;
  border-radius: 3px;
  background-color: #ffffff;
  color: #0081e9;
  margin-top: -112px;
    position: absolute;
  box-shadow: 5px 20px 20px 0 rgba(0, 0, 0, 0.06);
  height: 70px;
  font-size: 30px;
  width: 70px;
  border: none;
  outline: none;
  cursor: pointer;
}
.arrowDivProduct{
  margin-top: -108px;
  position: absolute; 
  justify-content: space-between;
}
.leftProd{
  background-image: url('../../assets/images/blueLeft.svg');
  background-repeat: no-repeat; 
  background-size: 43%;
  left: 0; 
  text-align: left;
  padding-left: 17px;
}
.rightProd{
  background-repeat: no-repeat; 
  background-size: 43%;
  background-image: url('../../assets/images/blueRight.svg'); 
  right: 0;
  text-align: right;
  padding-right: 17px;
}
.rightProd:hover{ 
  background-size: 30%;
}
.leftProd:hover{ 
  background-size: 30%;
}
.leftProd:hover,.rightProd:hover{
  width: 100px;
  box-shadow: -5px 20px 20px 0 rgba(0, 0, 0, 0.31);
}


@media screen and (max-width: 768px) {
  .logos{
    margin-top: 125px;
  }
  .partnersTextbold {
    margin-left: -88px;
  }
  .partnersText{
    font-size: 50px;
  }
  .clientsLogo{
    width: 285px;
    height: 285px;
  }
  .productsText{
    font-size: 50px;
    margin-left: 12px
  }
  .logoProduct{
    height: 177px;
    width: 620px;
    margin-top: 146px;
  }
  .name{
    font-size: 29px;
    margin-top: -84px;
    margin-left: 211px;
  }
  .aboutProduct{
    font-size: 26px;
    margin-left: 173px;
    height: 163px;
    margin-top: 17px;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
  } 

  .headerButton{
    margin-left: 5px;
    margin-top: 31px;
    padding: 23px 44px;
    font-size: 31px;
  } 
   .imageNews{
    height: 516px;
    width: 764px;
  }
  .NewsT{
    height: 516px;
  }

  .buttonProduct{
    margin-left: 172px;
  }
  .titleNews{ 
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  overflow: hidden;
    font-size: 48px;
  }
  .textNews{
    margin-top: -22px;
    font-size: 25px;
     -webkit-line-clamp: 3;
   -webkit-box-orient: vertical;  
     display: -webkit-box; 
     width: 82%;
    }
  .dateNews{
    width: 37%;
    font-size: 25px;
  }
  .newsArrows{
    height: 83px;
    font-size: 32px;
    width: 87px;
  }
  .arrowDivNews{
    margin-right: 118px;
    margin-top: -87px;
  }
  .productsArrows{
    height: 100px;
    font-size: 47px;
    width: 100px;
    margin-top: -157px;
  }
  .productsArrows:hover{
    width: 130px;
  }
  .FooterDiv{
    height: 154px;
  }
  .FooterDiv1{
    width: 23%;
  }
  .FooterDiv2{
    width: 77%;
  }
  .logoFooter{
    width: 68%;
    margin: 11% 18%;
  }
  .rights{
    font-size: 32px;
    margin: 46px 30px 0px 11px;
  }
  .line{
    height: 68px;
    margin-top: 31px;
  }
  .logosFooter{
    height: 71px;
    margin-top: 36px !important;
    margin: 0px 0px 3px 32px;
}
.span{
  margin-top: -520px;
  height: 515px;
  width: 203px;
}
.productsDiv{
  margin-top: 372px;
}
 
}

@media screen and (max-width: 415px) {
 .slide{
  height: 1827px;
  }
 
  .bigImg{
    height: 1307px;
    margin-top: 604px;
    margin-left: 123px;
  }
  .headerDiv{
    margin-left: 371px;
    margin-top: -1705px;
    width: 917px;
  }
  .Headertitle{
    width: 837px;
    font-size: 111px;
  }
  .bold{
    margin-left: 221px;
  }
  .bold2{
    margin-left: 146px;
    margin-top: 14px;
  }
.headerButton{
  margin-left: 174px; 
    padding: 36px 75px;
    font-size: 45px;
  }
  .bigImg2{
    height: 1215px;
    margin-top: 796px;
    margin-left: 14px;
  }
  .bigImg3{
    height: 1307px;
    margin-top: 656px;
    margin-left: 9px;
  }
  .bold3{
    width: 320px !important;

  }
  .headerDiv3{
    margin-left: 122px;
  }
  .button3{
    margin-left: 405px !important;
    margin-top: 80px;
  }
  .bigImg4{
    height: 1333px;
    margin-top: 665px;
    margin-left: 78px;
  }
 
  .productsDiv{
    margin-top: 1147px;
  }
  .partnersText{
    font-size: 112px;
  }
  .titlePartners{
    margin-top: 69px; 
    width: 67%;
  }
  .logos{
    padding-top: 228px;
  }
  .partnerDiv{
    width: 1152px;
   
  }
  .productTitle{
    margin-top: -204px;
    margin-left: 316px;
  }
  .productsText{
    font-size: 111px;
    margin-left: 51px;
  }
  .HImage{
    float: right;
    height: 1365px;
    width: 1521px;
  }
  .productT{
    float: right;
    width: 100%;  
    margin-top: 57px;
    height: 1500px;
  }
  .clientsLogo{ 
    transform: translateY(-46%);
    width: 600px;
    height: 600px;
    margin: 28% 77px; 
  }
 
 
  .productsArrows{
    height: 164px;
    font-size: 89px;
    width: 164px;
    margin-top: -1465px;
  }
  .productsArrows:hover{
    width: 250px;
  }
  .logoProduct{
    height: 323px;
    width: 1158px;
    margin-left: 241px; 
    margin-bottom: 80px;
    margin-top: 173px;
  }
  .aboutProduct{
    font-size: 49px;
    margin-left: 242px;
    max-height: 470px;
    margin-top: -19px;
    width: 1139px;
    height: 484px;
}
     .headerButton{ 
      padding: 45px 97px;
      font-size: 50px;
      margin-left: 133px;
     }
     .buttonProduct{
      margin-left: 559px;
     }
     .NewsTitle{
      height: 472px;
     }
     .newsText{
      margin-top: 230px;
      margin-left: 591px !important;
     }
     .newsT{
      width: 1525px;
      height: 1214px;
      margin-top: -25px;
     }
     .imageNews{
      width: 1287px;
      height: 868px;
     }
     .span{    
      margin-top: -2070px;
      height: 868px;
      width: 241px;
     }
     .newsArrows{
     height: 124px;
     font-size: 56px;
     width: 124px;
  } 
  .arrowDivNews{
    margin-right: 115px;
    margin-top: -1321px;
  }
  .titleNews{
    font-size: 74px;
    width: 938px;
    margin-left: 159px;
    margin-top: 102px;
  }
  .textNews{
    text-indent: 282px;
    font-size: 47px;
    height: 500px;
    width: 62%;
    max-height: 615px;
    margin-left: 153px;
  }
  .dateNews{
    width: 17%;
    margin-left: 161px;
    float: left;
    font-size: 40px;
    height: 10px;
    position: initial;
  }
  .buttonNews{
    margin-left: 149px !important;
  }
  .logoFooter{
    z-index: 1004;
    width: 30%;
    margin: 4% 35%;
  }
  .FooterDiv1{ 
    width: 100%;
    height: 247%;
  }
  .rights{
    position: absolute;
    font-size: 44px;
    margin: 447px 30px 0px 332px;
    color: #0060ad;
  }
  .socialMedia{
    margin-left: 466px;
    margin-bottom: 50px;
  }
  .logosFooter{
    margin: 38px 116px 68px 116px;
  }
}