.Div{
 float: left;
 width: 100%;
 scroll-behavior: smooth;  
 transition-duration: 1s;
}
.head{
    background-image: linear-gradient(rgb(46, 59, 91), rgb(36, 46, 71) 71%, rgb(15, 24, 54));
    width: 100%;
    height: 89px;
    position: fixed;
}
.titleDiv{
    float: left;  
    width: 100%;
    margin-top: 136px;
}
.mainTitle{    
    float: left; 
    margin-top: 6px;
    margin-left: 74px;
    font-family: Rubik-Regular;
    font-size: 43px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.18;
    letter-spacing: normal;
    text-align: left;
    color: #0081e9;
}
.bold{
    margin-left: 11px;
    font-family: Rubik-Bold !important;
}
.textDiv{
    margin-left: 50%;
}
.line{
    float: left;
    width: 60px; 
    border: solid 0.1px #dbdbdb;
}
.text{
    float: left;
    font-family: Rubik-Light;
    font-size: 18px;
    font-weight: 300;
    font-stretch: normal;
    font-style: italic;
    line-height: 1;
    margin-top: -6px;
    margin-left: 12px;
    letter-spacing: normal;
    text-align: left;
    color: #dbdbdb;
}
.aboutDiv{
    float: left;
    width: 100%;
}
.img1{
    float: left;
    width: 760px;
    height: 429px;
}
.originsDiv{
    width: 762px;
    background-color:  #f5f5f5;
    height: 1200px;
    float: left;
}
.originsTitle{ 
    font-family: 'Rubik-Light';
    font-size: 33px; 
    text-align: left;
    color: #222b4a;
    margin-left: 66px;
}
.originsText{
    margin-left: 66px;
    font-family: 'Rubik-Regular';
    font-size: 14px;
    width: 487px;
    line-height: 1.75;
    letter-spacing: normal;
    text-align: left;
    color: #8c8b8b;
    white-space: pre-wrap;
}
.sliderDiv{
    float: left;
    width: 114%;
    margin-top: 240px;
    margin-left: -105px;
}
.sliderImg{
    width: 723px !important;
    height: 496px;
}
.span{
    position: absolute;
    background-color: black;
    width: 146px;
    height: 496px;
    opacity: 0.6;
    right: 0;
    margin-top: -500px; 
}
.Arrows{
    background-image: url('../../assets/images/whiteRight.svg'); 
    background-repeat: no-repeat;
    background-position: center;
    background-size: 43%;
    background-color: #0081e9; 
    border-radius: 0px 3px 3px 0px;
    color: #ffffff; 
    height: 46px; 
    width: 46px;
    border: none;
    outline: none;
    cursor: pointer;
}
.left{
    opacity: 0.7;
    background-image: url('../../assets/images/whiteLeft.svg'); 
  border-radius: 3px 0px 0px 3px;
} 
.divArrows{
    margin-left: 678px;
    margin-top: -72px;
    z-index: 1001;
    position: absolute;
}
.Arrows:hover{
    background-color: #ee8856
  }
  .lifeDiv{
    margin-top: -40%;
    float: left;
    width: 591px;
    height: 519px; 
  }
  .lifeTitle{
    font-family: 'Rubik-Light';
    font-size: 33px; 
    text-align: left;
    color: #222b4a;
    margin-left: 66px;
    float: left;
    margin-right: 78% ;
  }
  .bold2{
    margin-right: -57px;
    margin-left: 88px;
    font-family: Rubik-Bold  
  }
  .lifeText{
      margin-left: 90px;
      margin-top: 129px;
  }
  .teamDiv{
    width: 100%;
    float: left;
    min-height: 1000px;
}
.peopleDiv{
    width: 33%;
    float: left;
}
.image{  
    border-radius: 100px;
    width: 193px;
    height: 193px;
    margin: 0 158px;
    background-color: #8c8b8b;
    align-self: center;
}
.titleDiv2{
    height: 130px;
    width: 100%;
    margin-top: 56px;
}
.name{
    font-family: Rubik-Medium;
    font-size: 21px;
    text-align: center;
    font-weight: 500;
    line-height: .8;
    color: #222b4a;
}
.about{ 
    font-family: Rubik-Regular;
    font-size: 19px;
    line-height: .5 ;
    text-align: center;
    color: #222b4a;
 
}
.socialDiv{
    display: flex;
    justify-content: center;
    align-items: center; 
    height: 27px;
    margin-bottom: 64px; 
}
.bioLogo{
    margin: 0 8px;
    float: left;
    font-family: Rubik-Medium;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.16;
    letter-spacing: 0.5px; 
    color: #3b435e;
    cursor: pointer;
}
.linkedin{
    font-size: 19px;
    margin: 0px 8px 1px;
}
.bioLogo:hover{
    color: #0081e9;
}
.founderImg{
    border-radius: 100px;
    width: 193px;
    margin: 0 666px;
    align-self: center;
}
.Modal{
    outline: none;
    background-color: #ffffff;
    margin: 183px 398px; 
    width: 663px;
    height: 400px;
}
.CloseButton{
    width: 36px;
    height: 36px;
    font-size: 18px;
    cursor: pointer;
    outline: none;
    margin: 15px;
    float: right;
    border-radius: 22px;
    background-color: #222b4a;
    color: #ffffff;
}
.CloseButton:hover{
    background-color: #0081e9  ;
}
body > div:nth-child(13) > div{
    background-color: rgba(34, 43, 74, 0.36) !important;
    z-index: 1005;
    opacity: 1;
 
}
.gray{
    float: left;
    height: 100%; 
    width: 16%;
    background-color: #f5f5f5;
    z-index: 999;

}
.modalDiv{
height: 100%;
    width: 100%;
}
.modalImg{
    margin-left: 18px;
    width: 159px;
    height: 159px;
    background-color: #8c8b8b;
    margin-top: 18px;
    border-radius: 100px; 
    align-self: center;
}
.infoDiv{
float: left;
    width: 213px;
    margin-left: 80px;
}
.infoName{
    width: 303px;
    margin-top: 59px;
    font-family: Rubik-Medium;
    font-size: 20px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.18;
    letter-spacing: normal;
    text-align: left;
    color: #222b4a;
}
.infoTitle{
    font-family: Rubik-Light;
    font-size: 18px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.19;
    letter-spacing: normal;
    text-align: left;
    color: #222b4a;
}
.infoAbout{
    white-space: break-spaces;
    font-family: Rubik-Regular;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: normal;
    text-align: left;
    color: #222b4a;
    width: 418px;
    margin-top: -35px;
    overflow-y: scroll;
    height: 156px;
}
.modalsocialDiv{
    display: flex; 
    align-items: center; 
    height: 27px;
    margin-bottom: 64px; 
}
.infoAbout::-webkit-scrollbar {
    width: 10px;               /* width of the entire scrollbar */
  }
  .infoAbout::-webkit-scrollbar-track {
    border-radius: 40px;
    background-color:  #f5f5f5
   
  }
  .infoAbout::-webkit-scrollbar-thumb {
    border-radius: 40px;
    background-color: #222b4a;  
  }
  .email{
    background-size: contain;
    width: 19px;
    height: 15px; 
    background-repeat: no-repeat;
    background-image: url('../../assets/images/email.svg');
  }
  .email:hover{ 
    background-image: url('../../assets/images/blueEmail.svg');
  }
  @media screen and (max-width: 768px) {
    .infoAbout::-webkit-scrollbar {
        width: 30px;
    }
    .mainTitle{
        margin-bottom: 0;
        margin-top: 100px;
        font-size: 60px;
    }
    .img1{
        width: 100%;
        height: auto;
    }
    .textDiv{
        position: absolute;
        margin-top: 80%;
    } 
    .text{
        font-size: 30px;    
    }
    .originsDiv{
        margin-top: 6%;
        width: 100%; 
        height: 541px;
    }
    .lifeDiv{
        margin-top: 30px;
    }
    .lifeText{
        margin-top: 201%;
    }
    .originsTitle{
        font-size: 48px;
    }
    .originsText{
        font-size: 27px;
        width: 1360px;
    }
    .sliderImg{
        width: 1325px !important;
        height: 913px;
    }
    .Arrows{
        height: 86px;
        font-size: 36px;
        width: 86px;
    }
    .divArrows{
        margin-left: 1240px;
        margin-top: -128px;
    }
    .teamDiv{
        margin-top: 1120px;
    }
    .founderImg{
        border-radius: 155px;
        width: 275px;
        margin: 0 631px;
    }
    .name{
        font-size: 34px;
    }
    .about{
        font-size: 30px;
    }
    .bioLogo{
        font-size: 29px;
        margin: 0 12px;
    }
    .email{
        width: 43px;
        height: 23px;
        margin-left: 22px;
    }
    .linkedin{
        font-size: 38px;
        margin: 0px 8px 5px;
    }
    .image{
        border-radius: 156px;
        width: 275px;
        height: 275px;
        margin: 0 220px;
    }
    .peopleDiv{
        width: 50%;
    }
    .socialDiv{
        margin-bottom: 108px;
    }
    .lifeTitle{
        font-size: 45px;
        margin-right: -50px !important;
    }
    .Modal{
        margin: 221px 0;
        width: 100%;
        height: 900px;
    }
    .modalImg{
        width: 300px;
        height: 300px;
        border-radius: 150px;
    }
    .gray{
        width: 12%;
    }
    .infoDiv{
        width: 1000px;
        margin-left: 206px;
    }
    .infoName{
        width: 703px;
        margin-top: 94px; 
        font-size: 43px;
    }
    .infoTitle {
        font-size: 32px;
        margin-top: -23px;
    }
    .infoAbout{ 
        font-size: 29px;
        padding: 37px;  
        width: 95%;
        margin-top: -14px;
        margin-left: -35px; 
        height: 466px;
    }
    .CloseButton{
        width: 70px;
        height: 70px;
        font-size: 37px; 
        border-radius: 61px;
    }
    .span{ 
        width: 306px;
        height: 914px; 
        margin-top: -917px;
    }
    .head{
        height: 155px;
    }
  }

@media screen and (max-width: 415px) {
    .titleDiv{
        margin-top: 430px;
    }
    .infoAbout::-webkit-scrollbar {
        width: 42px;
    }
    .head{
        height: 339px;
    }
    .mainTitle{
        font-size: 110px;
    }
    .img1{
        width: 100%;
    }
    .textDiv{
        margin-top: 107%;
        margin-left: 0px;
    }
    .line{
        width: 103px;
    }
    .text{
        font-size: 65px;
        width: 1288px;
    }
    .originsDiv{
        margin-top: 17%;
        width: 100%;
        height: 1632px;
    }
    .originsTitle{
        font-size: 96px;
        margin-left: 116px;
    }
    .originsText{
        margin-left: 126px;
        font-size: 54px;
    }
    .lifeDiv{
        width: 997px;
        margin-top: 71px;
    }
    .lifeTitle{
        font-size: 82px;
        margin-right: -38px !important;
    }
    .sliderDiv{
        margin-top: 342px;
        width: 106%;
    }
    .sliderImg{
        width: 1393px !important;
        height: 1039px;
    }
    .span{
        width: 218px;
        height: 1039px;
        margin-top: -1042px;
        margin-right: 18px;
    }
    .Arrows{
        height: 150px;
        font-size: 78px;
        width: 150px;
    }
    .divArrows{
        margin-left: 1244px;
        margin-top: -191px; 
    }
    .lifeText{
        margin-top: 138%;
    }
    .teamDiv{
        margin-top: 2408px;
        width: 100%;
    }
    .founderImg{
        border-radius: 445px;
        width: 669px;
        margin: 0px 427px;
    }
    .name{
        font-size: 76px;
    }
    .about{
        font-size: 68px;
    }
    .bioLogo{
        font-size: 59px;
    }
    .linkedin{
        font-size: 65px;
    margin: 0px 27px 5px;
    }
    .email{
        width: 85px;
    height: 49px;
    margin-left: 22px;
    }
    .peopleDiv{
        width: 100%;
    }
    .image{ 
        border-radius: 445px;
        width: 669px;
        height: 669px;
        margin: 0px 427px;
    }
    .Modal{
        margin: 341px 0;
        width: 100%;
        height: 2043px;
    }
    .gray{
        width: 0; 
    }
    .modalImg{
        width: 700px;
        height: 700px;
        border-radius: 500px;
        margin-left: 396px;
    }
    .infoDiv{
        margin-top: 700px;
        margin-left: 241px;
    }
    .infoName{
        width: auto;
        text-align: center;
        margin-top: 94px;
        font-size: 72px;
    }
    .infoTitle{
        font-size: 62px;
        text-align: center;
    }
    .infoAbout{
        font-size: 47px; 
        margin-left: -42px;
        width: 111%;
        height: 506px;
    }
    .modalsocialDiv{
        justify-content: center;
    }
    .CloseButton{
        width: 170px;
        height: 170px;
        font-size: 74px;
        border-radius: 106px;
    }
}
