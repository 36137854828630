.sideBar{
    float: left;
    width: 16%;
    height: 100%;
    z-index: 1000;
    background-color: white;
    position: fixed;
}
.mainDiv{
  
    z-index: 1000;
    float: left;
    width: 84%;
    margin-left: 241px;
    min-height: 749px;
    background-color: #ecedf1;
}
.rightBar{
    border-radius: 3px;
    right: 0;
    width: 4%;
    background-color: #f2f4fa;
    height: 100%;
    z-index: 1000;
    position: fixed;
}
.semperTitle{
    width: 51%;
    margin-top: 16px;
    padding: 46px;
}
.AdminButtons{
    border-radius: 44px;
    cursor: pointer;
    margin: 17px 23px 15px;
    padding: 5px 15px;
    float: left;
    width: 82%;
    background: none;
    border: none;
    outline: none;
}
.sidebarImg{
    float: left;
    width: 32px;
    margin: 8px 0px 0px;
}
.sidebarText{
    cursor: pointer;
    padding-left: 23px;
    margin: 14px -3px 16px;
    float: left;
    font-family: Rubik-Regular;
    font-weight: 900;
    font-size: 16px;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: left;
    color: #0060ad;
    outline: none;
}
.AdminButtons:focus{
    background-color: #f2f4fa;

}
.rightButton{
    cursor: pointer;
    border-radius: 21px;
    background-color:#ffffff;
    width: 69px;
    margin-left: -19px;
    margin-top: 31px;
    height: 37px;
}
.rightImg{
    height: 10px;
    margin: 15px 7.9px 14px 13px;
    float: left;
}
.p{
    font-family: Rubik-Regular;
    font-size: 22px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.8;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    margin: -1px 0px 0px 3.2px;
    background-color: #0980df;
    width: 39px;
    height: 39px;
    float: left;
    border-radius: 26px;
}
.loginDiv{
    width: 300px;
    margin: 159px 41%;
    height: 400px;
    align-content: center;
    background-color: #ffffff;
    border-radius: 10px;
}
.loginPage{
    background-color: #f2f4fa;
    min-height: 765px;
    z-index: auto;
    float: left;
    width: 100%;
}
.sempImg{
    padding: 37px 38px 13px;
    width: 40%;
    float: left;
}
.loginLabel{
    padding: 10px;
    font-family: Rubik-Medium;
    font-size: 15px;
    float: left;
    font-weight: 500;
    letter-spacing: normal;
    text-align: left;
    color: #0060ad;
    padding: 20px 37px;
}
.in{
    border: 2px solid;
    border-radius: 3px;
    background-color: #f2f4fa;
    font-family: Rubik-Regular;
    line-height: 1.21;
    text-align: left;
    color: #7a84a6;
    float: left;
    width: 221px;
    height: 35px;
    margin: 1px 37px 28px;
    border-color: #ffffff;
    outline-color: #0060ad;
}
.button{
    
    border-radius: 22px;
    cursor: pointer;
    border: none;
    outline: none;
    padding: 10px 20px;
    margin: 15px 0px 0px 181px;
    background-color: #0060ad;
    font-family: Rubik-Medium;
    font-size: 14px;
    text-align: left;
    color: #ffffff;
}
.logout{
    margin-left: -186px;
    position: absolute;
    cursor: pointer;
    margin-top: 32px; 
    float: left;
    font-family: Rubik-Regular;
    font-weight: 900;
    background-color: rgb(255, 255, 255);
    font-size: 16px;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: left;
    color: #0980df;;
    outline: none;
    padding: 9px 19px;
    border-radius: 29px;
    border: none;
}
.logout:hover{
    background-color:  #0980df;;
    color:rgb(255, 255, 255); 
}