.mainDiv{
    float: left;
    width: 100%;
    margin-top: 99px;
    scroll-behavior: smooth;  
    transition-duration: 1s;
}
.head{
    background-image: linear-gradient(rgb(46, 59, 91), rgb(36, 46, 71) 71%, rgb(15, 24, 54));
    width: 100%;
    height:89px;
    margin-top: -99px;
    position: fixed;
}   
.titleDiv{
    width: 100%;
    float: left;
}
.mainTitle{
    margin-left: 85px;
    margin-top: 92px;
    margin-right: 12px;
    font-family: Rubik-Regular;
    font-size: 43px; 
    line-height: 1.18; 
    text-align: left;
    color: #0081e9;
    float: left;
}
.bold{
    margin-left: 0px !important;
    font-family: Rubik-Bold;
}
.infoDiv{
    width: 29%;
    margin-left: 88px;
    float: left;
}

.policyDiv{
	width: 80%;
	margin-left: 88px;
	float: left;
}

.title{
    float: left;
    font-family: Rubik-Light;
    font-size: 40px;
    font-weight: 300; 
    line-height: 0.18;
    letter-spacing: 1px;
    text-align: left;
    color: #222b4a;
}
.subTitle{
    float: left;
    font-family: 'Rubik-Medium';
    font-size: 19px;
    font-weight: 500; 
    line-height: 1.2;
    letter-spacing: 1.2px;
    text-align: left;
    margin-top: 14px;
    color: #0081e9;
 
}
.info{
    float: left;
    font-family: Rubik-Regular;
    margin-top: 3px;
    font-size: 15px; 
    line-height: 1.67;
    letter-spacing: 0.96px;
    text-align: left;
    color: #8c8b8b;
}
.tel{
    margin-top: 0px;
    float: left;
    font-family: Rubik-Medium;
    font-size: 15px; 
    line-height: 1.67;
    letter-spacing: 0.96px;
    text-align: left;
    color: #0081e9 ;
    text-decoration: none;
}
.telNo{
    margin-top: 0px;
    margin-right: 55%;
    float: left;
    font-family: Rubik-Regular;
    font-size: 15px; 
    line-height: 1.67;
    letter-spacing: 0.76px;
    text-align: left;
    color: #8c8b8b;
}
.title2{
    width: 100%;
    float: left;
    margin-top: 80px; 
    font-family: Rubik-Light;
    font-size: 40px;
    font-weight: 300;
    line-height: 0.18;
    letter-spacing: 1px;
    text-align: left;
    color: #222b4a;
}
.title3{
    width: 100%;
    float: left;
    margin-top: 80px; 
    font-family: Rubik-Light;
    font-size: 30px;
    font-weight: 300;
    line-height: 0.18;
    letter-spacing: 1px;
    text-align: left;
    color: #222b4a;
}
.email{
    margin-top: 0px;
    float: left;
    font-family: Rubik-Light;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: 0.96px;
    text-align: left;
    color: #0081e9;
    text-decoration: none;
}
.email:hover{
    color:#ee8856
}
.messageDiv{
    margin-bottom: 140px;
    float: left;
    width: 45%;
    margin-left: 300px;
    background-color: #f5f5f5;
    height: 643px;
}
.messageTitle{
    font-family: Rubik-Light;
    font-size: 24px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.18;
    letter-spacing: normal;
    text-align: left;
    color: #a9bccc;
    margin-left: 62px;   
     margin-top: 49px;
}
.input{
    font-family: Rubik-Light;
    font-size: 15px; 
    letter-spacing: 0.96px;
    text-align: left;
    color: #8c8b8b;
    margin-top: 15px;
    margin-left: 62px;
    width: 200px;
    height: 38px;
    outline: none;
    float: left;
    border-radius: 3px;
    background-color: #ffffff;
    margin-right: 13px;
    border: none;
}
.label{
    margin-top: 20px;
    margin-left: 62px;
    float: left;
    font-family: Rubik-Regular;
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: 0.96px;
    text-align: left;
    color: #0081e9;
    margin-right: 167px;
}
.input2{
    font-family: Rubik-Light;
    font-size: 15px; 
    letter-spacing: 0.96px;
    text-align: left;
    color: #8c8b8b;
    margin-top: 17px;
    margin-left: 62px;
    width: 484px;
    height: 38px;
    outline: none;
    float: left;
    border-radius: 3px;
    background-color: #ffffff;
    margin-right: 13px;
    border: none;
}
.input3{
    padding: 16px;
    font-family: Rubik-Light;
    font-size: 15px;
    letter-spacing: 0.96px;
    text-align: left;
    color: #8c8b8b;
    margin-top: 17px;
    margin-left: 62px;
    width: 466px;
    height: 116px;
    outline: none;
    float: left;
    border-radius: 3px;
    background-color: #ffffff;
    margin-right: 13px;
    border: none;
    overflow-y: scroll;
}
.input3::-webkit-scrollbar {
    width: 10px;               /* width of the entire scrollbar */
  }
  .input3::-webkit-scrollbar-track {
    border-radius: 40px;
    background-color:  #f5f5f5
   
  }
  .input3::-webkit-scrollbar-thumb {
    border-radius: 40px;
    background-color: #0081e9;  
  }
  .button{
    float: right;
    cursor: pointer;
    outline: none;
    border: none;
    border-radius: 4px;
    box-shadow: 0 30px 30px 0 rgba(0, 0, 0, 0.12);
    background-color: #0081e9;
    padding: 14px 38px;
    margin-right: 136px;
    margin-top: 37px;
    font-family: Rubik-Medium;
    font-size: 12px;
    font-weight: bold; 
    line-height: 1.2;
    letter-spacing: 0.8px;
    text-align: left;
    color: #ffffff;
  } 
  .button:hover{
    background-color:#ee8856

  }
  .maps{
    width: 100%;
    margin-bottom: 0;
  }
@media screen and (max-width: 768px) {
    .head{
        height: 155px;
    }
    .mainTitle{  
        margin-left: 128px;
        margin-top: 120px; 
        font-size: 65px;
    }
    .infoDiv{
        width: 56%; 
        margin-bottom: 109px;
        margin-left: 128px;
    }
    .messageDiv{
        width: 100%;
        margin-left: 0px; 
        height:1079px;
    }
    .title{
        font-size: 66px;
        margin-right: 45%;
        margin-top: 29px;
    }
    .subTitle{
        font-size: 34px;
    }
    .info{
        font-size: 23px;
    }
    .telNo{
        margin-right: 66%; 
        font-size: 23px;
    }
    .tel,.email{ 
        font-size: 23px;
    }
    .title2{
        font-size: 66px;
        margin-top: 119px;
    }
    .title3{
	font-size : 44px;
	margin-top: 100px;
    }
    .messageTitle{
        font-size: 44px; 
        margin-left: 125px;
    }
    .label{
        margin-left: 145px; 
        font-size: 43px; 
        margin-right: 372px;
    }
    .input{ 
        font-size: 33px; 
        margin-left: 149px;
        width: 388px;
        height: 80px; 
        margin-right: 88px;
        border: none;
    }
    .input2{ 
        font-size: 33px; 
        margin-top: 17px;
        margin-left: 147px;
        width: 1035px;
        height: 80px;
        margin-right: 13px;
    }
    .input3{
        margin-left: 147px;
        width: 1035px;
        font-size: 33px; 
        height: 248px;
    }
    .button{    
        padding: 23px 51px;
        margin-right: 339px;
        margin-top: 37px; 
        font-size: 28px;
    }
   
}

@media screen and (max-width: 415px) { 
    
    .head{
        height: 339px;
    }
    .infoDiv{
        width: 68%;
    }
    
    .titleDiv{
        margin-top: 200px;
    }
    .mainTitle{ 
        font-size: 95px;
    }
    .title,.title2{
        font-size: 90px;
    }
    .subTitle{
        font-size: 52px;
    }
    .info,.tel,.telNo,.email{
        font-size: 45px;
    }
    .telNo{
        margin-right:50%;
    }
    .messageDiv{
        height: 1979px;
    }
    .messageTitle{
        font-size: 67px;
    }
    .label{
        font-size: 63px;
    }
    .input{

        font-size: 51px; 
          width: 1173px;
        height: 115px;
    }
    .inputIn{
        margin-top: -255px;
    }
    .labelIn{
        margin-top: 164px;
    }
    .label{
        margin-right: 520px;
    }
    .input2{
        width: 1173px;
        font-size: 51px; 
        height: 106px; 
    }
    .input3{ 
        width: 1173px;
        font-size: 51px; 
        height: 471px;
    }
    .button{
        margin-right: 188px;
        padding: 41px 94px; 
        margin-top: 73px;
        font-size: 60px;
    }

}
