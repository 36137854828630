.Title{
    font-family: Rubik-Light;
    margin: 53px 69px 35px;
    font-size: 32px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.18;
    letter-spacing: normal;
    text-align: left;
    color: #0060ad;
}
.createNew{
    cursor: pointer;
    width: 88%;
    margin: 7px 63px;
    border-radius: 10px;
    background-color: #ffffff;
    height: 100px;
    margin-top: 11px;
    padding: 0px 472px;
    border: none;
    outline: none;
}
.createTitle{
    float: left;
    font-family: Rubik-Regular;
    font-size: 12px;
    font-weight: 600;
    color: #0060ad;
}
.createImg{
    float: left;
    width: 20px;
    padding: 10px 10px;
}
.createDiv{
    width: 88%;
    margin: 14px 63px;
    border-radius: 10px;
    background-color: #ffffff;
    margin-top: 47px;
    height: 533px;
    border: none;
    outline: none;
}
.createTitle2{
    font-family: Rubik-Medium;
    font-size: 14px;
    text-align: left;
    padding: 27px 33px 10px;
    color: #0060ad;

}
.label{
    margin-top: 18px;
    float: left;
    font-family: Rubik-Medium;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.19;
    letter-spacing: normal;
    text-align: left;
    color: #0060ad;
    padding: 0px 80px 11px 33px;
}
.inputDiv{
    float: left;
    width: 28%;
}
.input{
    color: #7a84a6;
    width: 72%;
    height: 23px;
    padding: 8px;
    outline: none;
    float: left;
    border: none;
    border-radius: 3px;
    font-family: Rubik-Regular;
    background-color: #ecedf1;
    margin: 0px 33px;
}
.select{
    height: 41px;
    margin-right: 150px;
    width: 230px;
}
.minText{
    font-family: Rubik-Regular;
    font-size: 9px;
    line-height: 1.17;
    color: #8c8b8b;
    float: left;
    margin-left: 179px;
}
.createButton{
    border-radius: 22px;
    cursor: pointer;
    border: none;
    outline: none;
    padding: 10px 20px;
    margin: 24px 0px 18px 51px;
    background-color: #0060ad;
    font-family: Rubik-Medium;
    font-size: 14px;
    text-align: left;
    color: #ffffff;
}
.createButton:hover{
    background-color:   #004984;
}
.text{
    float: left;
    font-family: Rubik-Regular;
    font-size: 18px;
    margin-left: 37px;
    margin-top: 27px;
    font-weight: bold;
    text-align: left;
    width: 61%;
    color: #7a84a6;
}
.text2{
    font-family: Rubik-Regular;
    font-size: 15px;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: left;
    float: left;
    width: 42%;
    height: 10px;
    color: #7a84a6;
    margin-top: -6px;
    margin-left: 38px;
}
.adminDiv{
    width: 88%;
    margin: 7px 63px;
    border-radius: 10px;
    background-color: #ffffff;
    height: 100px;
}
.table{
    margin-top: -6px;
}
.adminImg{
    float: left;
    height: 63px;
    line-height: 2.8;
    font-family: Rubik-Medium;
    text-transform: capitalize;
    width: 63px;
    font-size: 22px;
    color: #ffffff; 
    border-radius: 68px;
    margin-left: 55px;
    margin-top: 22px;
    text-align: center;;
}
.delete2{
    border-radius: 22px;
    cursor: pointer;
    border: none;
    outline: none;
    padding: 10px 20px;
    float: left;
    margin: 24px 0px 24px 167px;
    background-color: #f35037;
    font-family: Rubik-Medium;
    font-size: 14px;
    text-align: left;
    color: #ffffff;
}
.delete{
    border-radius: 22px;
    cursor: pointer;
    border: none;
    outline: none;
    padding: 10px 20px;
    margin: 32px 12px;
    background-color: #f35037;
    font-family: Rubik-Medium;
    font-size: 14px;
    text-align: left;
    color: #ffffff;
}
.delete:hover{
    background-color: #a93826;
}
.update{
    border-radius: 22px;
    cursor: pointer;
    border: none;
    outline: none;
    padding: 10px 20px;
    margin: 32px 12px;
    background-color: #0060ad;
    font-family: Rubik-Medium;
    font-size: 14px;
    text-align: left;
    color: #ffffff;
}
.update:hover{
    background-color: #004984;
}
.updateDiv{
    float: left;
}

.logo{
    float: left;
    height: 123px;
    width: 128px;
    margin-left: 31px;
}
.div1{
    float: left;
    width: 287px;
}
.div11{
    float: left;
    width: 293px;
}
.div2{
    float: left;
    width: 322px;
}
.div3{
    width: 500px;
    float: left;
}
.dosyasec{
    float: left;
    width: 89px;
    height: 34px;
    opacity: 0;
    background-color: black;
    margin-top: 34px;
    margin-left: 20px;
}
.upload{
    border-radius: 22px;
    background-color: #3b435e;
    cursor: pointer;
    border: none;
    outline: none;
    padding: 10px 20px;
    margin: 32px 12px;
    font-family: Rubik-Medium;
    font-size: 14px;
    text-align: left;
    color: #ffffff;
    float: left;
    margin-left: 175px;
    margin-top: -36px;
    margin-bottom: 0px;

}
.link{
    color: #3b435e;
    margin-top: 93px;
    margin-left: -350px;

}
.inputLink{
    width: 183px;
    margin-left: 33px;
    margin-top: 41px;
    height: 23px;
}
.plus{
    object-fit: contain;
    border-radius: 23px;
    background-color: #3b435e;
    color: #ffffff;
    padding: 0px 8px;
    font-size: 30px;
    margin-top: 296px;
    margin-left: -67px;
    border: none;
    cursor: pointer;
    outline: none;
}
.inputT{
    height: 89px;
    width: 417px;
}
.email{
    width: 417px;
}
.grid{
 margin-left: -10px;
}   
.gridIn{
    width: 20px;
    float: left;
    margin-left: 16px;
    margin-right: 5px;
}
.gridIn2{
    width: 20px;
    float: left;
    margin-left: 5px;
    margin-right: 16px;
}
.x{
    float: left;
    font-weight: bolder;
    font-family: Rubik-Bold;
    cursor: pointer;
}
.gridIn::-webkit-inner-spin-button,.gridIn2::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
.socialDiv{
    float: left;
    max-height: 73px;
    margin-top: 6px;
    overflow: auto;
    width: 540px;
    border-radius: 3px;
    margin-left: 33px;
    background-color: #ecedf1;
}
.socialText{
    float: left;
    font-family: Rubik-Medium;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.9;
    letter-spacing: normal;
    padding-right: 37px;
}
.tooltip{
    visibility: hidden; 
    position: absolute;
    background-color: rgb(242, 244, 250);
    height: 224px;
    width: 180px;
    margin-top: 1px;
    left: 100%;
    border-radius: 8px;
    font-size: 14px;
    font-family: 'Rubik-Regular';
    color: black;
    padding: 12px;
}
.hoverDiv{
    position: absolute;
    width: 94px;
    margin-left: 174px;
    height: 30px;
    margin-top: 13px;
}
.hoverDiv2{
    position: absolute;
    width: 94px;
    margin-left: 174px;
    height: 30px;
    margin-top: 13px;
}
.hoverDiv2:hover .tooltip{
    visibility: visible;
    opacity: 1;
}
.hoverDiv:hover .tooltip{
    visibility: visible;
    opacity: 1;
}
.toolImg{
    height: 75px;
    margin-left: 24px;
}
.toolText{
    font-family: Rubik-Medium;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.19;
    letter-spacing: normal;
    text-align: left;
    color: #0060ad;
}
.left{
    border: none;
    background-color: inherit;
    outline: none;
    border-radius: 10px;
    margin-left: 65px;
    color: #0060ad;
    cursor: pointer;
}
.right{
    border: none;
    border-radius: 10px;
    background-color: inherit;
    outline: none;
    margin-left: 3px;
    color: #0060ad;
    cursor: pointer;
}
.right:hover,.left:hover{
    background-color: #0060ad;
    color: #ffffff;
}
.toolExample{
    height: 172px;
}