
#root > div > div > div > div.Home_divSlide__2saOm > div > ul{    
    width: 14%;
    position: absolute;
    right: 92px;
    margin-bottom: 50px;
}
#root > div > div > div > div.Home_divSlide__2saOm > div > ul > li:nth-child(1) > button::before,
#root > div > div > div > div.Home_divSlide__2saOm > div > ul > li:nth-child(2) > button::before,
#root > div > div > div > div.Home_divSlide__2saOm > div > ul > li:nth-child(3) > button::before,
#root > div > div > div > div.Home_divSlide__2saOm > div > ul > li:nth-child(4) > button::before{
    font-size: 22px;
}
#root > div > div > div > div.Home_partnersDiv__msteY > div.Home_logos__13yXL > div > div > div > div > div > div,
#root > div > div > div > div.Home_productsDiv__ZC9NI > div.slick-slider.slick-initialized > div > div > div.slick-slide.slick-active.slick-current > div > div,
#root > div > div > div > div.Home_NewsDiv__6YRlj > div.slick-slider.slick-initialized > div > div > div > div > div{
    outline: none;
}
#root > div > div > div.Contact_mainDiv__3oWtX > div.Contact_maps__CoIJJ{
    margin-bottom: -117px;
}
.slick-slider{
    outline: none;
}
#lang > option:nth-child(1),#lang > option:nth-child(2){
    background-color:  #131d3b ;
    border: none;
    outline: none;
}
 
#root > div > div > div.App_AppAll__2rHRz > div.Home_divSlide__3tE5K > div > ul > li:nth-child(n) > button::before{
    font-size: 26px;
}
#root > div > div > div.App_AppAll__2rHRz > div.Home_divSlide__3tE5K > div > ul{
    bottom: 18px;
    left: 42%;
}
#root > div > div > div.App_AppAll__2rHRz > div.Home_productsDiv__1ZRPb > div.slick-slider.slick-initialized > div > div > div.slick-slide.slick-active.slick-current > div > div,
#root > div > div > div.App_AppAll__2rHRz > div.Home_productsDiv__1ZRPb > div.slick-slider.slick-initialized > div > div > div:nth-child(n) > div > div,
#root > div > div > div.App_AppAll__2rHRz > div.Home_partnersDiv__B6CkT > div.Home_logos__24r62 > div > div > div > div:nth-child(n) > div > div,
#root > div > div > div.App_AppAll__2rHRz > div.Home_NewsDiv__3XG0Q > div.slick-slider.slick-initialized > div > div > div.slick-slide.slick-active.slick-current > div > div{
    outline: none;
}
@media screen and (max-width: 768px) {
    #root > div > div > div.Contact_mainDiv__KX8jx > div.Contact_maps__1ZbEC > iframe{
        height: 800px;
        margin-top: -140px;
    }
    #root > div > div > div.App_AppAll__2wbki > div.Home_NewsDiv__6YRlj > div:nth-child(n) > div > div{
        height: 500px;
    }
    #root > div > div > div.Contact_mainDiv__3oWtX > div.Contact_maps__CoIJJ > iframe{
        height: 1000px;
    }
}
@media screen and (max-width: 415px) {
    #root > div > div > div.App_AppAll__2wbki > div.Home_partnersDiv__msteY > div.Home_logos__13yXL > div > div > div > div:nth-child(n){
        width: auto !important; 
    } 
    #root > div > div > div.Contact_mainDiv__3oWtX > div.Contact_maps__CoIJJ > iframe{
        height: 1500px;
    }
    #root > div > div > div > div.Home_divSlide__2saOm > div > ul{    
        width: 90%; 
        right: 117px; 
        margin-bottom: -76px;
    }
    #root > div > div > div > div.Home_divSlide__2saOm > div > ul > li:nth-child(1) > button::before,
    #root > div > div > div > div.Home_divSlide__2saOm > div > ul > li:nth-child(2) > button::before,
    #root > div > div > div > div.Home_divSlide__2saOm > div > ul > li:nth-child(3) > button::before,
    #root > div > div > div > div.Home_divSlide__2saOm > div > ul > li:nth-child(4) > button::before{
        font-size: 42px;
        margin: 0 18px;
    }
    #root > div > div > div > div.Home_divSlide__2saOm > div > ul > li:nth-child(1),
    #root > div > div > div > div.Home_divSlide__2saOm > div > ul > li:nth-child(2),
    #root > div > div > div > div.Home_divSlide__2saOm > div > ul > li:nth-child(3),
    #root > div > div > div > div.Home_divSlide__2saOm > div > ul > li:nth-child(4){
        margin: 0 20px;
    } 
    #root > div > div > div.App_AppAll__2wbki > div.Home_NewsDiv__6YRlj > div:nth-child(5){
        z-index: 1003; 
        height: 1198px;
    }
    #root > div > div > div.Contact_mainDiv__KX8jx > div.Contact_maps__1ZbEC > iframe{
        height: 1600px; 
    }
  
    #root > div > div > div.App_AppAll__2aZ-A > div.Home_NewsDiv__1Cv4j > div:nth-child(5){
        height: 1198px;
    } 
    #root > div > div > div.App_AppAll__2rHRz > div.Home_NewsDiv__3XG0Q > div:nth-child(5) > div > div{
        width: 36624px !important;
    }
}

 
body > div.ReactModalPortal > div{
    background-color: rgba(34, 43, 74, 0.36) !important;
  } 
  body > div.ReactModalPortal > div{
      z-index: 1005;
  }
  
  #root > div > div > div.App_AppAll__2aZ-A > div.Home_partnersDiv__9nlbK > div.Home_logos__3hTjv > div > div > div > div:nth-child(n) > div > div{
      outline: none;
  }
 