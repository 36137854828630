.Title{
    font-family: Rubik-Light;
    margin: 53px 69px 35px;
    font-size: 32px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.18;
    letter-spacing: normal;
    text-align: left;
    color: #0060ad;
}
.createNew{
    cursor: pointer;
    width: 88%;
    margin: 7px 63px;
    border-radius: 10px;
    background-color: #ffffff;
    height: 100px;
    margin-top: 11px;
    padding: 0px 480px;
    border: none;
    outline: none;
}
.createTitle{
    float: left;
    font-family: Rubik-Regular;
    font-size: 12px;
    font-weight: 600;
    color: #0060ad;
}
.createImg{
    float: left;
    width: 20px;
    padding: 10px 10px;
}
.createDiv{
    width: 88%;
    margin: 14px 63px;
    border-radius: 10px;
    background-color: #ffffff;
    margin-top: 47px;
    height: 209px;
    border: none;
    outline: none;
}
.createTitle2{
    font-family: Rubik-Medium;
    font-size: 14px;
    text-align: left;
    padding: 27px 33px 10px;
    color: #0060ad;

}
.label{
    margin-top: 18px;
    float: left;
    font-family: Rubik-Medium;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.19;
    letter-spacing: normal;
    text-align: left;
    color: #0060ad;
    padding: 0px 80px 11px 33px;
}
.inputDiv{
    float: left;
    width: 28%;
}
.input{
    width: 71%;
    height: 23px;
    padding: 8px;
    outline: none;
    float: left;
    border: none;
    border-radius: 3px;
    font-family: Rubik-Regular;
    background-color: #ecedf1;
    margin: 0px 33px;
}
.minText{
    font-family: Rubik-Regular;
    font-size: 9px;
    line-height: 1.17;
    color: #8c8b8b;
    float: left;
    margin-left: 179px;
}
.createButton{
    border-radius: 22px;
    cursor: pointer;
    border: none;
    outline: none;
    padding: 10px 20px;
    margin: 22px 0px 18px 30px;
    background-color: #0060ad;
    font-family: Rubik-Medium;
    font-size: 14px;
    text-align: left;
    color: #ffffff;
}
.createButton:hover{
    background-color:   #004984;
}
.text{
    float: left;
    font-family: Rubik-Regular;
    font-size: 18px;
    margin-left: 37px;
    margin-top: 41px;
    font-weight: bold;
    text-align: left;
    width: 61%;
    color: #7a84a6;
}
.adminDiv{
    width: 88%;
    margin: 7px 63px;
    border-radius: 10px;
    background-color: #ffffff;
    height: 100px;
}
.table{
    margin-top: -6px;
}
.adminImg{
    float: left;
    height: 63px;
    line-height: 2.8;
    font-family: Rubik-Medium;
    text-transform: capitalize;
    width: 63px;
    font-size: 22px;
    color: #ffffff;
    background-color: #0980df;
    border-radius: 68px;
    margin-left: 55px;
    text-align: center;;
}
.delete2{
    border-radius: 22px;
    cursor: pointer;
    border: none;
    outline: none;
    padding: 10px 20px;
    float: left;
    margin: 24px 0px 24px 167px;
    background-color: #f35037;
    font-family: Rubik-Medium;
    font-size: 14px;
    text-align: left;
    color: #ffffff;
}
.delete{
    border-radius: 22px;
    cursor: pointer;
    border: none;
    outline: none;
    padding: 10px 20px;
    margin: 32px 12px;
    background-color: #f35037;
    font-family: Rubik-Medium;
    font-size: 14px;
    text-align: left;
    color: #ffffff;
}
.delete:hover{
    background-color: #a93826;
}
.update{
    border-radius: 22px;
    cursor: pointer;
    border: none;
    outline: none;
    padding: 10px 20px;
    margin: 32px 12px;
    background-color: #0060ad;
    font-family: Rubik-Medium;
    font-size: 14px;
    text-align: left;
    color: #ffffff;
}
.update:hover{
    background-color: #004984;
}
.updateDiv{
    float: left;
    margin-top: -7px;
}