.App {
  text-align: center;
  height: 1587px;
}
html{
  overflow-x: hidden;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  margin-top: -17px;
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  position: fixed;
}

.App-link {
  color: #61dafb;
}
.AppAll{
scroll-behavior: smooth;  
transition-duration: 1s;}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.header{
  width: 100%;
  height: 673px;
  margin-top: 0;
}
.Div{
  transition-timing-function: ease-in-out;
  transition: width 2s linear 1s;
  z-index: 1000;
  position: fixed;
  height: 89px;
  width: 100%;
}
.logo{
  cursor: pointer;
  width: 10%;
  float: left;
  margin-top: 23px;
  margin-left: 52px;
  margin-right: 32%
}

.select{ 
  margin-top: 27px;
  margin-left: 28px; 
  background:inherit;
  outline: none;
  border: none;
  color: #ffffff;
  font-family: Rubik-Light;
  font-size: 15px;
  font-weight: 600;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
  cursor: pointer;
}
select:hover{
  caret-color: #ee8856;
}
.linkText{
  margin-top: 25px;
  margin-right: 47px;
    font-family: Rubik-Medium;
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: left;
    color: #ffffff;
    float: left;
    outline: none;
    text-decoration: none;
    padding-left: 37px;
    border: none;
    background: inherit;
    cursor: pointer;
}
.linkText2{
  margin-top: 27px;
    margin-right: 37px;
    font-family: Rubik-Medium;
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: left;
    color:#0081e9;
    float: left;
    outline: none;
    text-decoration: none;
    padding-left: 37px;
    border: none;
    background: inherit;
    cursor: pointer;
}
.linkText:hover,.linkText2:hover,.langText:hover{
   color: #ee8856;
}
 
.slideButtonDiv{
  float: right;
  margin-top: 610px;
  position: absolute;
  margin-left: 1286px;
}
.slideButton{
  background-color: #3a425d;
  border-radius: 10px;
  border: none;
  outline: none;
  height: 20px;
  margin: 0px 8px;
  border: none;
  width: 20px;
  cursor: pointer;
}
.sidebarLogo{
  width: 4%;
  float: right;
  margin-right: 69px;
  margin-top: 45px;
  cursor: pointer;
}
@media screen and (max-width: 1540px) {
  .Div{ 
    height: 89px;
  }
}
@media screen and (max-width: 768px) {
  .Div{ 
    height: 155px;
  }
  .logo{
    margin-top: 36px; 
  }
  .sidebarLogo{
    margin-top: 50px; 
  }
}
@media screen and (max-width: 415px) {
  .logo{
    margin-left: 116px;
    width: 31% !important;
    margin-top: 105px;
  }
  .sidebarLogo{
    width: 9%; 
      margin-right: 122px;
      margin-top: 121px;
  }
  .Div{
  
    z-index: 1003; 
    height: 339px;
  }
}
