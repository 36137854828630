.Title{
    font-family: Rubik-Light;
    margin: 53px 69px 35px;
    font-size: 32px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.18;
    letter-spacing: normal;
    text-align: left;
    color: #0060ad;
}
.createNew{
    cursor: pointer;
    width: 88%;
    margin: 7px 63px;
    border-radius: 10px;
    background-color: #ffffff;
    height: 100px;
    margin-top: 11px;
    padding: 0px 480px;
    border: none;
    outline: none;
}
.createTitle{
    float: left;
    font-family: Rubik-Regular;
    font-size: 12px;
    font-weight: 600;
    color: #0060ad;
}
.createImg{
    float: left;
    width: 20px;
    padding: 10px 10px;
}
.createDiv{
    width: 85%;
    margin: 14px 63px;
    border-radius: 10px;
    background-color: #ffffff;
    margin-top: 47px;
    padding: 20px;
    height: 584px;
    border: none;
    outline: none;
}
.createTitle2{
    font-family: Rubik-Medium;
    font-size: 14px;
    text-align: left;
    padding: 27px 33px 10px;
    color: #0060ad;

}
.label{
    margin-right: 100px;
    margin-top: 18px;
    float: left;
    font-family: Rubik-Medium;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.19;
    letter-spacing: normal;
    text-align: left;
    color: #0060ad;
    padding: 0px 119px 11px 33px;
}

.inputDiv{
    float: left;
    width: 28%;
}
.input{
    color: #7a84a6;
    width: 236px;
    height: 23px;
    padding: 8px;
    outline: none;
    float: left;
    border: none;
    border-radius: 3px;
    font-family: Rubik-Regular;
    background-color: #ecedf1;
    margin: 0px 33px;
}
.select{
    height: 35px;
    margin-right: 150px;
    width: 257px;
}

.inputT{
    height: 145px;
    width: 445px;
}
.minText{
    font-family: Rubik-Regular;
    font-size: 9px;
    line-height: 1.17;
    color: #8c8b8b;
    float: left;
    margin-left: 179px;
}
.createButton{
    border-radius: 22px;
    cursor: pointer;
    border: none;
    outline: none;
    padding: 10px 20px;
    margin: 22px 0px 18px 30px;
    background-color: #0060ad;
    font-family: Rubik-Medium;
    font-size: 14px;
    text-align: left;
    color: #ffffff;
}
.createButton:hover{
    background-color:   #004984;
}
.text{
    float: left;
    font-family: Rubik-Regular;
    font-size: 18px;
    margin-left: 82px;
    margin-top: 41px;
    font-weight: bold;
    text-align: left;
    width: 65%;
    color: #7a84a6;
}
.newsDiv{
    width: 88%;
    margin: 7px 63px;
    border-radius: 10px;
    background-color: #ffffff;
    height: 100px;
}
.table{
    margin-top: -6px;
}
.projectImg{
    float: left;
    height: 63px;
    line-height: 2.8;
    font-family: Rubik-Medium;
    text-transform: capitalize;
    width: 63px;
    font-size: 22px;
    color: #ffffff;
    background-color: #0980df;
    border-radius: 68px;
    margin-left: 55px;
    text-align: center;;
}
.delete2{
    border-radius: 22px;
    cursor: pointer;
    border: none;
    outline: none;
    padding: 10px 20px;
    float: left;
    margin: 24px 0px 24px 790px;
    background-color: #f35037;
    font-family: Rubik-Medium;
    font-size: 14px;
    text-align: left;
    color: #ffffff;
}
.delete{
    border-radius: 22px;
    cursor: pointer;
    border: none;
    outline: none;
    padding: 10px 20px;
    margin: 32px 12px;
    background-color: #f35037;
    font-family: Rubik-Medium;
    font-size: 14px;
    text-align: left;
    color: #ffffff;
}
.delete:hover{
    background-color: #a93826;
}
.update{
    border-radius: 22px;
    cursor: pointer;
    border: none;
    outline: none;
    padding: 10px 20px;
    margin: 32px 12px;
    background-color: #0060ad;
    font-family: Rubik-Medium;
    font-size: 14px;
    text-align: left;
    color: #ffffff;
}
.update:hover{
    background-color: #004984;
}
.updateDiv{
    float: left;
}
.logo{
    float: left;
    height: 114px;
    width: 117px;
    margin-left: 31px;
}
.div1{
    float: left;
    width: 428px;
}
.div11{
    float: left;
    width: 293px;
}
.div2{
    float: left;
    width: 496px;
    margin-left: 136px;
}
.div3{
    float: left;
}
.dosyasec{
    float: left;
    width: 99px;
    margin-top: 79px;
    margin-left: 20px;
    opacity: 0;
  
}    
.upload{
    float: left;
    border-radius: 22px;
    background-color: #3b435e;
    cursor: pointer;
    border: none;
    outline: none;
    padding: 10px 20px;
    margin: 32px 12px;
    margin-left: -103px;
    margin-top: 71px;
    margin-bottom: 0px;
    font-family: Rubik-Medium;
    font-size: 14px;
    text-align: left;
    color: #ffffff;
}
.options{
    background-color: #ecedf1; 
}
.options::after{
    background-color: #ffffff;
}
.options:active{
    background-color: #8c8b8b;
}
.select.selector{
    color:red;
}
.titleIn{
    width: 510px;
}
.dateDiv{
    float: left;
    width: 281px;
}
.sourceDiv{
    float: left;
    width: 100px;
}
.left{
    float: left;
    margin-left: -277px;
    margin-top: 91px;
    cursor: pointer;
}
.right{
    float: left;
    margin-left: 2px;
    margin-top: 43px;
    cursor: pointer;
}
.multiImg{
    margin-left: 33px;

}
.imgX{
    float: left;
    cursor: pointer;
    margin-bottom: 0px;
    margin-top: 13px;
    margin-left: -165px;
}
