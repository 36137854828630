.Div{
    background-color: #f5f5f5;
    float: left;
    width: 100%;
    scroll-behavior: smooth;  
    transition-duration: 1s;
}
.head{
    background-image: linear-gradient(rgb(46, 59, 91), rgb(36, 46, 71) 71%, rgb(15, 24, 54));
    width: 100%;
    height: 89px;
    position: fixed;
}
.titleDiv{
    background-color: #ffffff;
    float: left;  
    width: 100%;
    margin-top: 90px;
}
.aiDiv{
    float: left;
    width: 50%;
    background-color: #ffffff;
}
.mainTitle{    
    float: left; 
    margin-top: 57px;
    margin-left: 13px;
    font-family: Rubik-Regular;
    font-size: 43px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.18;
    letter-spacing: normal;
    text-align: left;
    color: #0081e9;
} 
 
.bold{
    margin-left: 64px;
    font-family: Rubik-Bold !important;
}
.title{
    font-family: 'Rubik-Light';
    font-size: 33px;
    text-align: left;
    color: #222b4a;
    margin-left: 66px;
}
 .text{
    margin-left: 66px;
    font-family: 'Rubik-Regular';
    font-size: 16px;
    width: 442px;
    line-height: 1.75;
    letter-spacing: normal;
    text-align: left;
    color: #8c8b8b;
    margin-bottom: 100px;
    white-space: pre-wrap;
 }
 .aiImg{   
    height: 693px;
    width: 42%;
    margin-left: -30px;
    margin-top: -51px;
    float: left;
 }
 .prodDiv{
    position: relative;
     float: left;
    height: 500px;
    width: 50%;
 }
 .cyberDiv{
    background-image: linear-gradient(to bottom, #534e9b, #0e0e51);
 }
 .futureDiv{
    background-image: linear-gradient(to bottom, #808490, #172038);
 }
 .prodImg{
    width: 595px;
}
.cyberImg{ 
    margin-top: 76px;
    margin-left: 172px;
}
.BlueTitle{
    float: left;
    font-family: Rubik-Regular;
    font-size: 43px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: left;
    color: #3f3d81;
    margin-left: 140px;
}
.bold2{
    margin-left: 15px !important;
    margin-right: 117px !important;
    font-family: Rubik-Bold !important;
}
.TextDiv{
    min-height: 513px;
    float: left;
    width: 50%;
}
.cyberText{
    float: left;
    margin-left: 142px;
    font-family: 'Rubik-Regular';
    font-size: 15px;
    width: 442px;
    line-height: 1.75;
    letter-spacing: normal;
    text-align: left;
    color: #8c8b8b;
    margin-bottom: 100px;
    white-space: pre-wrap;
}
.whiteDiv{
    margin-top: -203px;
    float: left;
    width: 50%; 
    min-height: 187px;
    background-color: #ffffff;
}
.prodList{ 
    width: 66%;
    height: 60%;
    text-align: end;
    float: right;
    margin-top: 18%;
}
.line{
    margin-left: 266px;
    float: left;
    width: 62px;
    height: 0;
    border: solid 0.1px #0081e9;
}
.prodListTitle{
    margin-top: -12px;
    float: left;
    font-family: Rubik-Medium;
    font-size: 25px;
    font-weight: bold;
    line-height: 1;
    text-align: left;
    color: #0081e9;
    margin-left: 11px;
    margin-bottom: 0px;
}
.bold3{
    font-family: Rubik-Bold !important;
    margin-bottom: -29px;
}
.bold5{
    font-family: Rubik-Bold !important;
    margin-bottom: -29px;
    margin-right: 99px; 
    color: #42495c;
}
.bigText{
    float: left;
    margin-left: 142px;
    font-family: 'Rubik-Regular';
    font-size: 14px;
    width: 442px;
    line-height: 1.75;
    letter-spacing: normal;
    text-align: left;
    color: #8c8b8b;
    margin-bottom: 7px;
    white-space: pre-wrap;
}
.bold4{
    font-family: Rubik-Bold !important; 
}
.bigDiv{ 
    background-image: linear-gradient(to bottom, #465c9e, #17295a);
}
.smartDiv{ 
    background-image: linear-gradient(to bottom, #374366, #182343);
}
.bigImg{
    margin-top: 112px;
    margin-left: -49px;
}
.bigColor{
    color: #43599a;
}
.futureColor{
    margin-right: 99px; 
    color: #42495c;
}
.smartColor{
    color: #354164;
}
.whiteLeft{
    margin-left: 50%;
    margin-right: 0;
}
.prodListLeft{
    width: 66%;
    height: 60%;
    text-align: end;
    float: left;
    margin-top: 20%;
    margin-left: -246px;
}
.Column{
    width: 100%;
    float: left;
}
.iotText{ 
    margin-right: 146px;
    margin-top: -33px;
}
.whiteFuture{
    margin-top: -518px;
}
.futureImg{
    margin-top: 59px;
    margin-left: 210px;
}
.smartImg{
    margin-top: 12px;
    margin-left: -16px;
}
.whiteSmart{
    margin-top: 0;
}
.productsDiv{
    margin-top:16px;
    max-height: 155px;
    width: 100%;
    text-align: left;
    margin-left: 342px;
}
.prodName{
    margin-bottom: 10px;
    float: left;
    width: 50%;
    font-family: 'Rubik-Light';
    font-size: 22px;
    font-weight: 300;
    line-height: 0.8;
    text-align: left;
    color: #0081e9;
    margin-top: 10px;
}
.prodName:hover,.prodNameLeft:hover{
    color: #ee8856;
}
.prodNameLeft{
    margin-bottom: 10px;
    float: right;
    width: 50%;
    font-family: 'Rubik-Light';
    font-size: 22px;
    font-weight: 300;
    line-height: 0.8; 
    color: #0081e9;
    margin-top: 10px;
    text-align: right;
}
.productsDivLeft{
    margin-left: -54px;
    margin-top: 16px;
    max-height: 155px;
    width: 100%;
    text-align: right;
}
@media screen and (max-width: 768px) {
    .mainTitle{
        margin-top: 85px; 
        font-size: 50px;
    }
    .productsDivLeft{
        margin-left: 57px;
    }
    .head{
        height: 155px;
    }
    .title{
        font-size: 43px;
    }
    .text{
        margin-left: 76px;
        font-size: 33px;
        width: 1371px;
        margin-top: 1018px;
    }
    .aiImg{
        height: 1129px;
        width: 64%;
        margin-left: -444px;
        margin-top: -23px; 
    }
    .Div{
        background-color:#ffffff
    }
    .prodDiv{
        height: 826px;
        width: 100%;
    }
    .prodImg{
        width: 1013px;
    }
    .cyberImg{
        margin-top: 120px;
    }
    .BlueTitle{ 
        font-size: 82px;   
        color: #ffffff;
        margin-left: 59px;
        margin-top: -50%; 
        position: absolute;
    }
    .bold2{
        margin-left: 301px !important;
    }
    .line2{
        margin-left: -21px;
    }
    .whiteDiv{
        margin-left: 263px;
        margin-top: -1035px;
        margin-right: -39px;
        float: left;
        width: 95%;
        height: 353px;
        background-color: #ffffff;
    }
    .whiteCyber{
        position: absolute;
        margin-top: 934px;
        z-index: 969;
        background-color: inherit;
    }
    .whiteBig{
        position: absolute;
        margin-top: 706px !important;
        z-index: 969;
        background-color: inherit;
    }
    .prodList{
        width: 66%;
        height: 198px;
        margin-top: 8%;
        text-align: end;
        float: left; 
        margin-left: -4px;
    }
    .prodListLeft{
        margin-top: 20%;
        margin-left: -4px;
    }
    .prodListTitle{
        font-size: 50px;
        margin-bottom: -15px;
    }
    .prodNameLeft{
        width: 43%;
        font-size: 40px; 
        text-align: left; 
        float: left;
        margin-top: 23px;
        margin-right: 43px;
        font-size: 40px;
    }
    .cyberText{
        font-size: 24px;
        margin-top: 487px;
        width: 1061px;
    }
    .bigDiv{
        margin-top: -1982px;
    }
    .bigImg{
        margin-top: 201px;
        margin-left: 181px;
    }
    .bigColor{
        margin-right: 117px;
        z-index: 1002;
        margin-top: 116px;
    }
    .bold3{
        margin-top: 25px !important;
    }
    .bigText2{
        margin-top: 1308px;
    }
    .bigText{
        font-size: 24px;
        width: 1296px;
        margin-bottom: 41px;
    }
    .whiteLeft,.whiteLeft{
        margin-left: -2%;
        margin-top: -1208px;
        margin-right: 1093px;
    }
    .prodName{
        font-size: 38px;
    }
    .productsDiv{
        margin-top: 42px;
    }
    .futureImg{
        margin-top: 124px;
    }
    .bold5{
        margin-top: -793px !important;
    }
    .futureColor{
        margin-top: -47% !important;
    }
    .whiteFuture{
        max-height: 296px;
        min-height: auto;
        position: absolute;
        margin-top: 910px !important;
        z-index: 969;
        background-color: inherit;
    }
    .smartColor{
        margin-top: 5% !important;
        z-index: 1002;
    }
    .iotText{
        margin-top: 11% !important;
    }
    .smartImg {
        margin-top: 45px;
        margin-left: 189px;
    }
    .whiteSmart {
        position: absolute;
        margin-top: 706px;
        z-index: 969;
        background-color: inherit; 
        margin-left: -6px;
    }
    .smartText{
        margin-top: 1311px;
    }
    .smartDiv{
        margin-top: -1811px;
    }
}
@media screen and (max-width: 415px) {
    .titleDiv{
        margin-top: 325px; 
    }
    .mainTitle{
        margin-top: 169px;
        font-size: 125px;
    }
    .bold{
        margin-left: 141px;
    }
    .title{
        font-size: 75px;
        margin-top: -40px;
        margin-left: 159px;
        width: 100%;
    }
    .head{
        height: 339px;
    }
    .aiImg{
        width: 73%;
        margin-left: -534px;
        margin-top: 52px;
    }
    .whiteCyber{ 
        margin-top: 1401px !important;
    }
    .text{
        margin-left: 141px;
    font-size: 54px;
    width: 1317px;
    margin-top: 1179px;
    }
    .prodDiv{
        height: 1326px;
    }
    .cyberImg{
        margin-top: 456px;
        margin-left: 129px;
    }
    .prodImg{
        width: 1231px;
    }
    .BlueTitle{
        font-size: 135px; 
        margin-left: 268px;
        margin-top: -80%;
    }
    .bold2 {
        margin-left: 675px !important;
    }
    .whiteDiv{
        width: 72%;
        height: 465px;
        margin-top: -2543px;
    }
    .line2{
        margin-left: -80px;
        margin-top: 63px;
    }
    .line{
        width: 113px;
    }
    .prodListTitle{
        margin-top: -27px;
        font-size: 75px;
        margin-bottom: -4px;
        margin-right: 172px;
    }
    .prodTitleCyber{
        margin-top: 50px;
    }
    .prodNameLeft,.prodName{  
        font-size: 69px; 
    }
    .cyberText{
        font-size: 53px;
        margin-top: 572px;
        width: 1196px;
    }
    .bigImg{
        margin-top: 520px;
        margin-left: 140px;
    }
    .whiteFuture{
        margin-top: -1543px;
    }
    .whiteSmart{
        margin-top: 1381px !important;
        margin-left: 245px;
    }
    .whiteLeft{
        margin-top: 1296px;
        height: 657px;
        width: 81%;
    }
    .bigColor{
        margin-top: 12%;
    }
    .bigDiv{
        margin-top: -4470px;
    }
    .bigText{
        font-size: 53px;
    }
    .bigText2{
        margin-top: 2078px; 
    }
    .prodList{
        width: 81%;
    }
    .productsDiv{
        margin-left: 379px;
    }
    .whiteFuture{
        margin-top: 1425px !important;
        max-height: 468px;
    }
    .futureImg{
        margin-top: 382px;
    }
    .bold5 {
        margin-top: -1253px !important;
    }
    .futureColor{
        margin-top: -72% !important;
    }
    .smartText {
        margin-top: 2123px;
    }
    .smartDiv {
        margin-top: -3585px;
    }
    .smartImg{
        margin-top: 255px;
    }
    .whiteBig{
        margin-top: 1253px !important;
    }
    .iotText {
        margin-top: 14% !important;
    } 
    .line3{ 
        margin-left: -19px;
    }
    .prodsTitleFuture{
        margin-top: 31px;
    }
    .productsDiv2{
        margin-left: 114px;
    }
}