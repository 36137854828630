.productDiv{
    width: 100%;
    float: left;
    min-height: 716px;
}
.divAll{
    width: 100%;
    height: 100%;
    scroll-behavior: smooth;  
    transition-duration: 1s;
}
.head{
    background-image: linear-gradient(rgb(46, 59, 91), rgb(36, 46, 71) 71%, rgb(15, 24, 54));
    width: 100%;
    height: 89px;
    position: fixed;
}
.title{
    margin-top: 204px;
    float: left;
    font-family: Rubik-Regular;
    font-size: 43px;
    line-height: 1.19;
    text-align: left;
    color: #0081e9;
    margin-left: 8px;
}
.bold{
    margin-left: 90px;
    font-family: Rubik-Bold 
}
.logo{
    margin-right: 18%;
    float: left;
    width: 400px;
    margin-left: 90px;
}
.HImage{
    margin-left: 135px;
    width: 703px;
    float: left;
    right: 0;
}
.about{
    max-height: 284px;
    overflow: hidden;
    width: 475px;
    margin-left: 100px;
    float: left;
    font-family: Rubik-Light;
    font-size: 16px;
    font-weight: 300; 
    line-height: 1.5;
    letter-spacing: 0.48px;
    text-align: left;
    color: var(--dark-blue-grey);
}
.textDiv{ 
    float: left;
    width: 43%;
    margin: 84px 0px;
}
.button{
    border: none;
    background-color: #0081e9; 
    margin-left: 100px; 
    float: left;
    font-family: Rubik-Medium;
    font-size: 15px;
    margin-top: 19px;
    padding: 13px 33px;
    border-radius: 4px;
    outline: none;
    box-shadow: 0 30px 30px 0 rgba(0, 0, 0, 0.19); 
    letter-spacing: 0.8px;
    text-align: left;
    color: #ffffff;
}
.button:hover{
    background-color: #ee8856
}
@media screen and (max-width: 768px) {
    .logo{ 
        position: absolute;
        width: 759px;
        margin-top: -3%;
    }
    .about{
        margin-top: 1687px;
        position: absolute;
        margin-left: 210px;
        width: 1115px;
        font-size: 32px;
    }
    .HImage{
        margin-top: 270px;
        width: 1500px; 
        position: absolute;
    }
    .productDiv{
        min-height: 2432px;
    }
    .button{ 
        margin-left: 224px;
        font-size: 29px;
        margin-top: 2217px;
        padding: 26px 52px; 
    }
    .title{
        margin-top: 289px;
        font-size: 88px;
    }
    .head{
        height: 155px;
    }
}
@media screen and (max-width: 415px) {
    .productDiv{
        min-height: 3532px;
    }
    .head{
        height: 339px;
    }
    .title{
        margin-top: 404px;
        font-size: 113px;
    }
    .logo{
        width: 1210px;
    }
    .HImage{
        margin-top: 401px;
    }
    .about{
        margin-top: 1929px;
        position: absolute;
        margin-left: 210px;
        width: 1184px;
        font-size: 51px;
        max-height: 1070px;
    }     
    .button{
        font-size: 50px;
        margin-top: 3046px;
        padding: 43px 85px;
    }  
}