.Sidediv{
    background-color:#131d3b ;
    width:20%;
    height:30px
}
.sidebar{
    height: 100%;
    width: 525px;
    position: fixed;
    z-index: 1200;
    right: -100%;
    top: 0;
    background-color: #131d3b;
    animation: slide-open 0.8s forwards;
}
.sidebar.close {
    right: -100%;
}
@keyframes slide-open {
    100% {right:0}
}
.sideText{ 
    float: left;
    font-family: Rubik-Medium;
    margin-top: 165px;
    margin-left: 75px;
    outline: none;
    font-size: 66px; 
    text-align: left;
    color: #ffffff;
    text-decoration: none;
    border: none;
    background: inherit;
    cursor: pointer;
}
.select{ 
    outline: none;
    margin-top: 95px;
    margin-left: 75px;
    margin-bottom: 38px;
    background: inherit;
    border: none; 
    font-family: Rubik-Light;
    font-size: 44px;
    font-weight: 600;
    letter-spacing: normal;
    text-align: left;
    width: 195px;
  }
  @media screen and (min-width: 415px) { 
      .sideText{
    margin-top: 144px;
    font-size: 46px;
  }
  .select{
    width: 142px;
    margin-left: 75px;
    margin-bottom: 10px; 
    font-size: 41px;
  }
}